import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { Carousel, CardVertical, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const CarouselPage: React.FC = () => {

    const renderCards = () => {
        return Array.from(Array(6).keys()).map((el, i) => {
            const index = i;
            if (index % 2 === 0) {
                return (
                    <CardVertical
                        title={`${index + 1} Year Term`}
                        contentItems={[
                            { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                            { title: 'Base Payment', description: ['N/A'] },
                            { title: 'Term', description: ['36 Monthly'] },
                            { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
                        ]}
                        actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                        dataUI={getDataUI()}
                    />
                )
            }
            return (
                <CardVertical
                    {...(index === 1 && {
                        tooltipText: "This loan option matches the terms requested by the applicant.",
                        badge: {
                            icon: 'fal fa-clipboard-check',
                            title: 'Terms Requested'
                        }
                    })}
                    title={`${index + 1} Year Term`}
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                    ]}
                    dataUI={getDataUI()}
                />
            )
        })
    }
    return (
        <div>
            <h1 className="mb-s">Carousel</h1>
            <p className="mb-l">Custom Carousel is a slideshow for cycling through a series of content.</p>

            <p className="mb-m">In the example for Carousel items is used CardVertical component check documentation <Link to="/docs/card-vertical" title="CardVertical doc">here</Link></p>

            <h3 className="mb-s">Default</h3>
            <Carousel
                items={renderCards()}
                dataUI={getDataUI()}
            />

            <h3 className="mb-s">Carousel with one slide</h3>
            <Carousel
                className="mb-l"
                itemsToShow={3}
                items={renderCards().slice(0, 3)}
                dataUI={getDataUI()}
            />


            <Highlight className="React">
                {
                    `
import React from 'react';
import {Carousel, CardVertical,  Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
        <Carousel
            dataUI={getDataUI()}
            items={[
                <CardVertical
                        title={"1 Year Term"}
                        contentItems={[
                            { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                            { title: 'Base Payment', description: ['N/A'] },
                            { title: 'Term', description: ['36 Monthly'] },
                            { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
                        ]}
                        actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                        dataUI={getDataUI()}
                />,
                <CardVertical
                    tooltipText="This loan option matches the terms requested by the applicant."
                    badge={
                        icon: 'fal fa-clipboard-check',
                        title: 'Terms Requested'
                    }
                    title={"2 Year Term"}
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                    ]}
                    dataUI={getDataUI()}
                />,
                ...
            ]}
        />



        <Carousel
                itemsToShow={3}
                oneSlideCarouselCompactItems
                dataUI={getDataUI()}
                items={[
                    <CardVertical
                            dataUI={getDataUI()}
                            title={"1 Year Term"}
                            contentItems={[
                                { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                                { title: 'Base Payment', description: ['N/A'] },
                                { title: 'Term', description: ['36 Monthly'] },
                                { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
                            ]}
                            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                    />,
                    <CardVertical
                        dataUI={getDataUI()}
                        title={"2 Year Term"}
                        actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                        contentItems={[
                            { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                            { title: 'Base Payment', description: ['N/A'] },
                            { title: 'Term', description: ['36 Monthly'] },
                            { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                        ]}
                    />,
                    ...
                ]}
                oneSlideCarouselCompactItems
        />
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>itemsToShow</td>
                        <td>number</td>
                        <td>4</td>
                        <td>Sets the number of items to show on every slide.</td>
                    </tr>
                    <tr>
                        <td>items</td>
                        <td>array of JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets Carousel items.</td>
                    </tr>
                    <tr>
                        <td>alwaysFluidItemsWidth</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set items to have fluid width, when Carousel is with only one slide. By default the items will be with max-width: 253px.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default CarouselPage;
