import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    MainContainerScrollable, EmptyReport, Button, ActionSettings
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const DemoEmptyReport = () => {
    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Presentation Report']} />
                        <ActionSettings dataUI={getDataUI()} isActive>
                            <Button dataUI={getDataUI()} btnType="secondary" icon="far fa-times-circle">Close and return</Button>
                        </ActionSettings>
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <div className="text-center mt-xl">
                    <EmptyReport />
                    <h1 className="mt-l">Looks like there is nothing in the report to print.</h1>
                </div>
            </MainContainerScrollable>
        </ReactAux>
    );
}

export default DemoEmptyReport;