import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorStatus, Container } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const DemoPageErrorsInner = ({ status }: { status: '401' | '403' | '404' | '500' }) => {
    const history = useHistory();

    // This is only for demo purpose
    const [isDark, setIsDark] = useState(false);
    useEffect(() => {
        const rootElement = document.documentElement;
        const rootStyles = getComputedStyle(rootElement);
        const colorValue = rootStyles.getPropertyValue('--color-1').trim();

        if (colorValue.toLowerCase() === '#ffffff') {
            setIsDark(false);
        } else {
            setIsDark(true);
        }
    }, []);

    return (
        <Container
            className="flex-column height-100vh"
            background="color-1"
            padding="L"
            dataUI={getDataUI()}
        >
            <img
                src={isDark ?
                    "./img/JackHenryLending_LoanVantage_Logo_white.png"
                    :
                    "./img/JackHenryLending_LoanVantage_Logo_navy.png"
                }
                alt="JackHenry Logo"
                width="448px"
                height="43px"
            />
            <ErrorStatus
                status={status}
                onButtonClick={() => {
                    history.push('/components')
                }}
            />
        </Container>
    );
}

export default DemoPageErrorsInner;