import React, { useState, useRef, useCallback, useMemo } from 'react';
import Highlight from 'react-highlight';
import { Editor, EditorTools, EditorContainer, Button, Toggle, Input, Tooltip, classNameHelper, Modal, kendoEditorInsertNbspIntoEmptyTags, kendoEditorRemoveNbspFromTags } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const DatepickerPage: React.FC = () => {

    const {
        Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
        ForeColor, BackColor,
        AlignLeft, AlignCenter, AlignRight, AlignJustify,
        Indent, Outdent, OrderedList, UnorderedList,
        Undo, Redo, FontSize, FontName, FormatBlock,
        InsertImage, ViewHtml,
        InsertTable,
        AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
        DeleteRow, DeleteColumn, DeleteTable,
        MergeCells, SplitCell, Unlink,
    } = EditorTools;

    const editorRef = useRef<any>();
    const [value, setValue] = React.useState("<p>Editor default value...</p>");
    const [editorDisabled, setEditorDisabled] = useState(false);
    const [inputVal, setInputValue] = useState('');
    const [showInsertMedia, setShowInsertMedia] = useState(false);

    const customToolRendering = useCallback((Tool: any, colorPicker = false) => {
        return function (props: any) {
            return (
                <Tool
                    {...props}
                    disabled={editorDisabled}
                    // Set disabled for color picker and text color
                    {...(colorPicker && editorDisabled) && { colorPickerProps: { disabled: editorDisabled } }}
                />
            );
        };
    }, [editorDisabled]);

    const onAddEditorContent = (content: string) => {
        const view = editorRef?.current?.view;
        const state = view.state;
        const tr = state.tr;
        const currentContent = state.schema.text(content);

        tr.replaceSelectionWith(currentContent, true);
        view.dispatch(tr);
    }

    const onEditorValueChange = (e: any) => {
        setValue(e.html);
    }

    const onEditorValueBlur = (e: any) => {
        setValue(kendoEditorInsertNbspIntoEmptyTags(e.target.value));
    }

    const onToggleEditorDisabled = () => {
        setEditorDisabled(!editorDisabled)
    }

    const InsertMedia = () => {
        return <Button disabled={editorDisabled} onClick={() => setShowInsertMedia(true)} icon="fal fa-images" btnType='secondary' dataUI={getDataUI()}>Insert Media Library Image</Button>
    }


    const highlightElement = useMemo(() =>
        <Highlight className="React">
            {
                `
import React from 'react';
import { Editor, EditorTools, EditorContainer, Tooltip, Button, classNameHelper, Modal, kendoEditorInsertNbspIntoEmptyTags, kendoEditorRemoveNbspFromTags } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const {
        Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
        ForeColor, BackColor,
        AlignLeft, AlignCenter, AlignRight, AlignJustify,
        Indent, Outdent, OrderedList, UnorderedList,
        Undo, Redo, FontSize, FontName, FormatBlock,
        InsertImage, ViewHtml,
        InsertTable,
        AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
        DeleteRow, DeleteColumn, DeleteTable,
        MergeCells, SplitCell, Unlink,
    } = EditorTools;

    const editorRef = useRef<any>();
    const [value, setValue] = React.useState("<p>Editor default value...</p>");
    const [editorDisabled, setEditorDisabled] = useState(false);
    const [inputVal, setInputValue] = useState('');

    const customToolRendering = useCallback((Tool: any, colorPicker = false) => {
        return function (props: any) {
            return (
                <Tool
                    {...props}
                    disabled={editorDisabled}
                    // Set disabled for color picker and text color
                    {...(colorPicker && editorDisabled) && { colorPickerProps: { disabled: editorDisabled } }}
                />
            );
        };
    }, [editorDisabled]);

    const onAddEditorContent = (content: string) => {
        const view = editorRef?.current?.view;
        const state = view.state;
        const tr = state.tr;
        const currentContent = state.schema.text(content);

        tr.replaceSelectionWith(currentContent, true);
        view.dispatch(tr);
    }

    const onEditorValueChange = (e: any) => {
        setValue(e.html);
    }

    const onEditorValueBlur = (e: any) => {
        setValue(kendoEditorInsertNbspIntoEmptyTags(e.target.value));
    }

    const onToggleEditorDisabled = () => {
        setEditorDisabled(!editorDisabled)
    }

    const InsertMedia = () => {
        return <Button disabled={editorDisabled} onClick={()=> setShowInsertMedia(true)} icon="fal fa-file-image" btnType='secondary' dataUI={getDataUI()}>Insert Media Library Image</Button>
    }

    return (
    <div>     
        <EditorContainer dataUI={getDataUI()}>
            <Editor
                ref={editorRef}
                value={kendoEditorRemoveNbspFromTags(value)}
                onChange={onEditorValueChange}
                onBlur={onEditorValueBlur}
                className={classNameHelper(editorDisabled && "lv-disabled-editor")}
                tools={[
                    [customToolRendering(Bold), customToolRendering(Italic), customToolRendering(Underline), customToolRendering(Strikethrough),
                    customToolRendering(Subscript), customToolRendering(Superscript),
                    customToolRendering(ForeColor, true), customToolRendering(BackColor, true),
                    customToolRendering(AlignLeft), customToolRendering(AlignCenter), customToolRendering(AlignRight), customToolRendering(AlignJustify),
                    customToolRendering(Indent), customToolRendering(Outdent),
                    customToolRendering(OrderedList), customToolRendering(UnorderedList),
                    customToolRendering(FontSize), customToolRendering(FontName), customToolRendering(FormatBlock),
                    customToolRendering(Undo), customToolRendering(Redo),
                    customToolRendering(InsertImage), customToolRendering(ViewHtml),
                    customToolRendering(InsertTable), customToolRendering(AddRowBefore), customToolRendering(AddRowAfter),
                    customToolRendering(AddColumnBefore), customToolRendering(AddColumnAfter),
                    customToolRendering(DeleteRow), customToolRendering(DeleteColumn), customToolRendering(DeleteTable),
                    customToolRendering(MergeCells), customToolRendering(SplitCell),
                    customToolRendering(EditorTools.Link), customToolRendering(Unlink),
                        () => <Tooltip title="DSO"><Button disabled={editorDisabled} dataUI={getDataUI()} btnType="narrow">DSO</Button></Tooltip>,
                        () => <Tooltip title="UCF"><Button disabled={editorDisabled} dataUI={getDataUI()} btnType="narrow">UCF</Button></Tooltip>,
                        () => <Tooltip title="TCF"><Button disabled={editorDisabled} dataUI={getDataUI()} btnType="narrow">TCF</Button></Tooltip>,
                        InsertMedia
                    ]
                ]}
                contentStyle={{ height: 300, color: 'red' }}
            />
        </EditorContainer>

        <p className="text-bold mt-m">
            Editor render value:
        </p>
        <div className="section-container">
            <div dangerouslySetInnerHTML={{ __html: value }} />
        </div>

        <Modal
            dataUI={getDataUI()}
            title="Insert media modal"
            isVisible={showInsertMedia}
            size="S"
            onClose={() => setShowInsertMedia(false)}
        >
            <div style={{ height: '200px', background: 'var(--color-select)' }} />
        </Modal>


        <div>
            <p className='text-bold'>
                Editor custom controls
            </p>
            <Toggle className="mb-s" dataUI={getDataUI()} onChange={onToggleEditorDisabled} checked={editorDisabled}>Disabled</Toggle>
            <p>Add text to last cursor position</p>
            <div className='d-flex mb-l'>
                <Input disabled={!!editorDisabled} value={inputVal} onChange={(e) => setInputValue(e.target.value)} className="width-m mr-s" dataUI={getDataUI()} />
                <Button
                    disabled={!!editorDisabled}
                    dataUI={getDataUI()}
                    onClick={() => {
                        onAddEditorContent(inputVal)
                    }}
                >
                    Add
                </Button>
            </div>
        </div>
    </div>
    );
}

export default Example;
    `}
        </Highlight>
        , []);

    return (
        <div>
            <h1 className="mb-s">Editor</h1>
            <p className="mb-s">The Editor component export <a rel="noopener noreferrer" title='Kendo React Editor' target='_blank' href='https://www.telerik.com/kendo-react-ui/components/editor/get-started/'>Kendo React Editor</a> and apply design system styles on it.</p>
            <p className="mb-m">There is helper component EditorRightPanel for rendering content in the right side of the editor see <a rel="noopener noreferrer" title='Demo' target='_blank' href='/components/merge-email'>Demo here</a>.</p>
            <p className="mb-m">
                <strong>Note</strong>: Always wrap <strong>Editor</strong> component in <strong>EditorContainer</strong> to prevent styling issues.
            </p>

            <div className="mb-l">
                <p className="mb-xs">
                    <strong>Note</strong>: Handling Empty Elements:
                </p>
                <p>
                    To address <a rel="noopener noreferrer" title='Kendo React Editor' target='_blank' href='https://docs.telerik.com/blazor-ui/knowledge-base/editor-missing-br-in-value#cause'>issues</a> with empty elements in KendoEditor, we’ve provided two helper functions that ensure proper spacing by preventing elements from being empty during both saving and rendering processes.
                </p>
                <ul>
                    <li>
                        <strong>kendoEditorInsertNbspIntoEmptyTags</strong>: This function should be called during the onBlur event. After the user finishes typing in the editor, it inserts non-breaking spaces ({'&nbsp;'}) into any empty elements, ensuring that they maintain spacing when the content is saved and later rendered.
                    </li>
                    <li>
                        <strong>kendoEditorRemoveNbspFromTags</strong>: Use this function when passing the value back into the editor. It removes the {'&nbsp;'} characters, making the elements truly empty again for further editing in the KendoEditor.
                    </li>
                </ul>
            </div>

            <div className="mb-m">

                <EditorContainer dataUI={getDataUI()}>
                    <Editor
                        ref={editorRef}
                        value={kendoEditorRemoveNbspFromTags(value)}
                        onChange={onEditorValueChange}
                        onBlur={onEditorValueBlur}
                        className={classNameHelper(editorDisabled && "lv-disabled-editor")}
                        tools={[
                            [customToolRendering(Bold), customToolRendering(Italic), customToolRendering(Underline), customToolRendering(Strikethrough),
                            customToolRendering(Subscript), customToolRendering(Superscript),
                            customToolRendering(ForeColor, true), customToolRendering(BackColor, true),
                            customToolRendering(AlignLeft), customToolRendering(AlignCenter), customToolRendering(AlignRight), customToolRendering(AlignJustify),
                            customToolRendering(Indent), customToolRendering(Outdent),
                            customToolRendering(OrderedList), customToolRendering(UnorderedList),
                            customToolRendering(FontSize), customToolRendering(FontName), customToolRendering(FormatBlock),
                            customToolRendering(Undo), customToolRendering(Redo),
                            customToolRendering(InsertImage), customToolRendering(ViewHtml),
                            customToolRendering(InsertTable), customToolRendering(AddRowBefore), customToolRendering(AddRowAfter),
                            customToolRendering(AddColumnBefore), customToolRendering(AddColumnAfter),
                            customToolRendering(DeleteRow), customToolRendering(DeleteColumn), customToolRendering(DeleteTable),
                            customToolRendering(MergeCells), customToolRendering(SplitCell),
                            customToolRendering(EditorTools.Link), customToolRendering(Unlink),
                            () => <Tooltip title="DSO"><Button disabled={editorDisabled} dataUI={getDataUI()} btnType="narrow">DSO</Button></Tooltip>,
                            () => <Tooltip title="UCF"><Button disabled={editorDisabled} dataUI={getDataUI()} btnType="narrow">UCF</Button></Tooltip>,
                            () => <Tooltip title="TCF"><Button disabled={editorDisabled} dataUI={getDataUI()} btnType="narrow">TCF</Button></Tooltip>,
                                InsertMedia
                            ]
                        ]}
                        contentStyle={{ height: 300, color: 'red' }}

                    />
                </EditorContainer>


                <p className="text-bold mt-m">
                    Editor render value:
                </p>
                <div className="section-container">
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                </div>

                <Modal
                    dataUI={getDataUI()}
                    title="Insert media modal"
                    isVisible={showInsertMedia}
                    size="S"
                    onClose={() => setShowInsertMedia(false)}
                >
                    <div style={{ height: '200px', background: 'var(--color-select)' }} />
                </Modal>
            </div>

            <div>
                <p className='text-bold'>
                    Editor custom controls
                </p>
                <Toggle className="mb-s" dataUI={getDataUI()} onChange={onToggleEditorDisabled} checked={editorDisabled}>Disabled</Toggle>
                <p>Add text to last cursor position</p>
                <div className='d-flex mb-l'>
                    <Input disabled={!!editorDisabled} value={inputVal} onChange={(e) => setInputValue(e.target.value)} className="width-m mr-s" dataUI={getDataUI()} />
                    <Button
                        disabled={!!editorDisabled}
                        dataUI={getDataUI()}
                        onClick={() => {
                            onAddEditorContent(inputVal)
                        }}
                    >
                        Add
                    </Button>
                </div>
            </div>

            {highlightElement}

            <h2 className="mb-m">EditorContainer API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native div attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native div attributes like: "style", "className", "data", etc.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DatepickerPage;