import React, { useState } from 'react';
import Highlight from 'react-highlight';

import { WorkQueue, WorkQueueItem, WorkQueueItemProps, SortableWorkQueueItemsProps } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const wqItems: SortableWorkQueueItemsProps = [
    {
        title: "Logan photography",
        details: "2290",
        color: 'default',
        id: 1
    },
    {
        title: "Invitation WQ",
        details: "23232",
        color: 'blue',
        id: 2
    },
    {
        title: "Allan A Morgan",
        details: "23232",
        color: 'green',
        id: 3
    },
    {
        title: "Logan photography",
        details: "2290",
        color: 'orange',
        id: 4
    },
    {
        title: "Logan photography SE",
        details: "2290",
        color: 'pink',
        id: 5
    }
];

const WorkQueuePage: React.FC = () => {

    const [itemOneColor, setItemOneColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemTwoColor, setItemTwoColor] = useState<WorkQueueItemProps['color']>('blue');
    const [itemThreeColor, setItemThreeColor] = useState<WorkQueueItemProps['color']>('green');
    const [itemFourColor, setItemFourColor] = useState<WorkQueueItemProps['color']>('yellow');
    const [itemFiveColor, setItemFiveColor] = useState<WorkQueueItemProps['color']>('orange');
    const [itemSixColor, setItemSixColor] = useState<WorkQueueItemProps['color']>('pink');
    const [itemSevenColor, setItemSevenColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemEightColor, setItemEightColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemNineColor, setItemNineColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemTenColor, setItemTenColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemElevenColor, setItemElevenColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemTwelveColor, setItemTwelveColor] = useState<WorkQueueItemProps['color']>('default');
    const [ordableWqItems, setOrdableWQItems] = useState(wqItems);

    const onColorChangeWQ = (color: WorkQueueItemProps['color'], id: string | number) => {
        const updatedWQ = ordableWqItems.map(wq => {
            if (wq.id === id) {
                return {
                    ...wq,
                    color: color
                }
            }
            return wq;
        });

        setOrdableWQItems(updatedWQ);
    }

    return (
        <div>
            <h1 className="mb-s">WorkQueue</h1>

            <p className="mb-l">
                The WorkQueue is a container for specific WorkQueue items, which are
                designed for resizable dashboard sections. These items adapt to the size of their
                wrapper container and expose color customization options.
            </p>

            <h2 className="mb-m">WorkQueue module components:</h2>
            <p className="mb-s"><strong><a href="#api-work-queue">WorkQueue</a></strong> - The wrapper for all single items.</p>
            <p className="mb-xl"><strong><a href="#api-work-queue-item">WorkQueueItem</a></strong> - A separate flexible and customizable card element.</p>

            <div style={{ padding: '20px 0' }}>
                <WorkQueue dataUI={getDataUI()}>
                    <WorkQueueItem
                        onColorChange={color => setItemOneColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemOneColor}
                        onClick={() => console.log('Click on WorkQueueItem 1')}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemTwoColor(color)}
                        title="Invitation WQ"
                        details="23232"
                        color={itemTwoColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemThreeColor(color)}
                        title="Allan A Morgan"
                        details="23232"
                        color={itemThreeColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemFourColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemFourColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemFiveColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemFiveColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemSixColor(color)}
                        title="Invitation WQ"
                        details="23232"
                        color={itemSixColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemSevenColor(color)}
                        title="Allan A Morgan"
                        details="23232"
                        color={itemSevenColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemEightColor(color)}
                        title="Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan"
                        details="2290"
                        color={itemEightColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemNineColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemNineColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemTenColor(color)}
                        title="Invitation WQ"
                        details="23232"
                        color={itemTenColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        onColorChange={color => setItemElevenColor(color)}
                        title="Allan A Morgan"
                        details="23232"
                        color={itemElevenColor}
                        dataUI={getDataUI()}
                    />
                    <WorkQueueItem
                        isLoading
                        onColorChange={color => setItemTwelveColor(color)}
                        title="Logan photography"
                        details="2290"
                        color={itemTwelveColor}
                        dataUI={getDataUI()}
                    />
                </WorkQueue>
            </div>

            <div className='mb-xl'>
                <h2 className="mb-m">WorkQueue reordable:</h2>

                <WorkQueue
                    dataUI={getDataUI()}
                    reordable
                    workQueueItems={ordableWqItems}
                    onReorderItems={(items) => {
                        console.log("Reordered WQ items", items);
                        setOrdableWQItems(items)
                    }}
                >
                    {
                        ordableWqItems.map((wqItem) => {
                            return (
                                <WorkQueueItem
                                    reordable
                                    dataUI={getDataUI()}
                                    key={wqItem.id}
                                    {...wqItem}
                                    onClick={() => {
                                        console.log('Clicked WQ', wqItem)
                                    }}
                                    onColorChange={(color) => {
                                        onColorChangeWQ(color, wqItem.id);
                                    }}
                                />
                            )
                        })
                    }
                </WorkQueue>
            </div>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { WorkQueue, WorkQueueItem, WorkQueueItemProps, SortableWorkQueueItemsProps } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const wqItems: SortableWorkQueueItemsProps = [
        {
            title: "Logan photography",
            details: "2290",
            color: 'default',
            id: 1
        },
        {
            title: "Invitation WQ",
            details: "23232",
            color: 'blue',
            id: 2
        },
        {
            title: "Allan A Morgan",
            details: "23232",
            color: 'green',
            id: 3
        },
        {
            title: "Logan photography",
            details: "2290",
            color: 'orange',
            id: 4
        },
        {
            title: "Logan photography SE",
            details: "2290",
            color: 'pink',
            id: 5
        }
];

class Example extends React.Component {

    const [itemOneColor, setItemOneColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemTwoColor, setItemTwoColor] = useState<WorkQueueItemProps['color']>('blue');
    const [itemThreeColor, setItemThreeColor] = useState<WorkQueueItemProps['color']>('green');
    const [itemFourColor, setItemFourColor] = useState<WorkQueueItemProps['color']>('yellow');
    const [itemFiveColor, setItemFiveColor] = useState<WorkQueueItemProps['color']>('orange');
    const [itemSixColor, setItemSixColor] = useState<WorkQueueItemProps['color']>('pink');
    const [itemSevenColor, setItemSevenColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemEightColor, setItemEightColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemNineColor, setItemNineColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemTenColor, setItemTenColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemElevenColor, setItemElevenColor] = useState<WorkQueueItemProps['color']>('default');
    const [itemTwelveColor, setItemTwelveColor] = useState<WorkQueueItemProps['color']>('default');

    const [ordableWqItems, setOrdableWQItems] = useState(wqItems);

    const onColorChangeWQ = (color: WorkQueueItemProps['color'], id: string | number) => {
        const updatedWQ = ordableWqItems.map(wq => {
            if (wq.id === id) {
                return {
                    ...wq,
                    color: color
                }
            }
            return wq;
        });

        setOrdableWQItems(updatedWQ);
    }
    
    render() {
        return (
            <WorkQueue dataUI={getDataUI()}>
                <WorkQueueItem
                    onColorChange={color => setItemOneColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemOneColor}
                    onClick={() => console.log('Click on WorkQueueItem 1')}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemTwoColor(color)}
                    title="Invitation WQ"
                    details="23232"
                    color={itemTwoColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemThreeColor(color)}
                    title="Allan A Morgan"
                    details="23232"
                    color={itemThreeColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemFourColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemFourColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemFiveColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemFiveColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemSixColor(color)}
                    title="Invitation WQ"
                    details="23232"
                    color={itemSixColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemSevenColor(color)}
                    title="Allan A Morgan"
                    details="23232"
                    color={itemSevenColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemEightColor(color)}
                    title="Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan"
                    details="2290"
                    color={itemEightColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemNineColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemNineColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemTenColor(color)}
                    title="Invitation WQ"
                    details="23232"
                    color={itemTenColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemElevenColor(color)}
                    title="Allan A Morgan"
                    details="23232"
                    color={itemElevenColor}
                    dataUI={getDataUI()}
                />
                <WorkQueueItem
                    onColorChange={color => setItemTwelveColor(color)}
                    title="Logan photography"
                    details="2290"
                    color={itemTwelveColor}
                    dataUI={getDataUI()}
                />
            </WorkQueue>


            // WorkQueue reordable
            <WorkQueue
                    dataUI={getDataUI()}
                    reordable
                    workQueueItems={ordableWqItems}
                    onReorderItems={(items) => {
                        setOrdableWQItems(items)
                    }}
                >
                    {
                        ordableWqItems.map((wqItem) => {
                            return (
                                <WorkQueueItem
                                    reordable
                                    key={wqItem.id}
                                    {...wqItem}
                                    onClick={() => {
                                        console.log('Clicked WQ', wqItem)
                                    }}
                                    onColorChange={(color) => {
                                        onColorChangeWQ(color, wqItem.id);
                                    }}
                                />
                            )
                        })
                    }
        </WorkQueue>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3 id="api-work-queue">WorkQueue</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>reordable</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Enable component to reorder WorkQueueItems</td>
                    </tr>
                    <tr>
                        <td>workQueueItems</td>
                        <td>(WorkQueueItemProps & {"{ id: string | number, chosen?: boolean, selected?: boolean }"}{"[]"})</td>
                        <td className="text-center">-</td>
                        <td>Sets list of WorkQueueItem when used component in type reordable.</td>
                    </tr>
                    <tr>
                        <td>onReorderItems</td>
                        <td>(orderedItems?: WorkQueueItemProps & {"{ id: string | number, chosen?: boolean, selected?: boolean }"}) {'=>'} void</td>
                        <td>null</td>
                        <td>Callback, executed after items reorder.</td>
                    </tr>
                    <tr>
                        <td>dataID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Attaches a data-id attribute with the passed in value.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-work-queue-item">WorkQueueItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title text in the center of the WorkQueueItem card.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the additional text below the title, wrapped in parenthesis.</td>
                    </tr>
                    <tr>
                        <td>color</td>
                        <td>'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink'</td>
                        <td>'default'</td>
                        <td>Sets the color style of the current WorkQueueItem.</td>
                    </tr>
                    <tr>
                        <td>onColorChange</td>
                        <td>(color: 'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'pink') {'=>'} void</td>
                        <td>null</td>
                        <td>Callback to execute when the color of the WorkQueueItem is changed in the colors dropdown.</td>
                    </tr>
                    <tr>
                        <td>onClick</td>
                        <td>(e?: React.MouseEvent) {'=>'} void</td>
                        <td>null</td>
                        <td>Callback, executed after click on the WorkQueueItem (ignoring clicks on the dropdown icon and its list items).</td>
                    </tr>
                    <tr>
                        <td>dataID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Attaches a data-id attribute with the passed in value.</td>
                    </tr>
                    <tr>
                        <td>reordable</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set component reordable state</td>
                    </tr>
                    <tr>
                        <td>isLoading</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Indicates if the WorkQueueItem should be displayed in loading state.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
};

export default WorkQueuePage;
